import axios from 'axios';
import dayjs from 'dayjs';
import { baseURL, baseEventsURL } from '@/shared/constants';
import { getLastUpdatedRide } from '@/helpers/functions';
import { highlightResults, dealerToSearchItem } from '@/shared/search';
import { allOrNothing } from '@/helpers/functions';
import { getLang } from '@/helpers/i18n';
import { getIdToken } from './gigya';
import { queryClient } from '..';


export const REACT_QUERY_KEYS = {
    fetchPOI: 'fetchPOI'
}

// Generic function to fetch data imperatively from ReactQuery
export const fetchReactQueryData = async (queryKey, queryFn, options = {}) => {
    try {
        const data = await queryClient.fetchQuery({
            queryKey,
            queryFn,
            ...options,
        });
        return data;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
};

/* */
// Helpers
const getQueryParams = (params) =>
    Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');

const urlGetter =
    (name, url, key = 'id', isApiV2 = false) =>
    (id, getState = () => ({})) => {
        const cachedRide = getLastUpdatedRide();
        if (cachedRide?.shortId === id) return Promise.resolve(cachedRide);

        const apiInstance = isApiV2 ? apiForEvents : api;
        const pluralName = `${name}s`;
        // try to fetch the item from state
        const item = ((getState()[pluralName] || {}).data || []).find(
            (item) => item[key] === id
        );
        // it's cached, money
        if (item) return Promise.resolve(item);
        if (name === 'ride')
            return apiInstance
                .get(`${url}/${id}?includeWeather=imperial`)
                .then((resp) => resp.data[name]);
        // otherwise hit the api
        const lang = getLang().toUpperCase();

        if (apiInstance == apiForEvents) {
            return apiInstance
                .get(`/eventserv/v2${url}/${id}`, {
                    headers: { 'Accept-Language': `${lang},EN` }
                })
                .then((resp) => resp.data[name]);
        } else {
            return apiInstance
                .get(`${url}/${id}`, {
                    headers: { 'Accept-Language': `${lang},EN` }
                })
                .then((resp) => {
                    return resp.data[name];
                });
        }
    };
//------------------------------------------------------------------------------
// API Instances ----------------------------------------------------------------
const api = axios.create({ baseURL });

const apiForEvents = axios.create({
    baseURL: baseEventsURL
});

//------------------------------------------------------------------------------
// Cancel Source Factory -------------------------------------------------------
export const getCancelSource = () => {
    return axios.CancelToken.source();
};
//------------------------------------------------------------------------------
// Is the thrown error from a cancel -------------------------------------------
export const isCancel = (error) => {
    return axios.isCancel(error);
};
//------------------------------------------------------------------------------
// Auth Token Check Interceptor ------------------------------------------------
api.interceptors.response.use(
    function (response) {
        // hd backend may return a good status with an embeded error message
        const { error } = response.data;
        if (error) {
            // backend is serializing error codes as strings for now
            const code = parseInt(error.code, 10);
            if (code !== 0) {
                return Promise.reject({ ...error, code });
            }
        }
        // unwrap nested response data
        const { data } = response.data;
        if (data) {
            return { ...response, data, originalData: response.data };
        }
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
);
//------------------------------------------------------------------------------
// GEt Auth Token --------------------------------------------------------------
export const getAPIToken = () => {
    return api.post(`session`, {});
};
//------------------------------------------------------------------------------
// Set Auth Token --------------------------------------------------------------
let authMiddleware = undefined;
export const setAPIToken = (token) => {
    removeAPIToken();

    if (token) {
        authMiddleware = api.interceptors.request.use(function (config) {
            config.headers['Authorization'] = `Bearer ${token}`;
            return config;
        });
    }
};

//------------------------------------------------------------------------------
// Remove Auth Token -----------------------------------------------------------
export const removeAPIToken = () => {
    if (typeof authMiddleware === 'undefined') return;
    api.interceptors.request.eject(authMiddleware);
    authMiddleware = undefined;
};
//------------------------------------------------------------------------------
// Get JWT ---------------------------------------------------------------------
export const getSession = (
    UID,
    UIDSignature,
    signatureTimestamp,
    dataCenter,
    id_token
) =>
    api
        .post(`session`, {
            UID,
            UIDSignature,
            id_token,
            signatureTimestamp,
            authType: 'WEB',
            dataCenter
        })
        .then((resp) => resp.data);

//------------------------------------------------------------------------------
// Rides -----------------------------------------------------------------------
export const getRides = (
    lat,
    lon,
    radiusInMiles,
    type = false,
    includeBookmarked = false
) => {
    let paramsObj = {
        params: allOrNothing({
            lat,
            lon,
            radiusInMiles,
            includeLocationHistory: true,
            type: !!type ? type : 'CUSTOM,CURATED',
            includeBookmarked: includeBookmarked
        })
    };
    return api.get(`rides`, paramsObj);
};

export const getRidesWithParams = (params = {}) => {
    const paramsObj = {
        params: {
            includeLocationHistory: false,
            includeBookmarked: false,
            ...params
        }
    };
    return api.get(`rides`, paramsObj);
};

export const getRideById = urlGetter('ride', '/rides', 'id');
export const getRideByShortId = urlGetter(
    'ride',
    '/rides/byShortId',
    'shortId'
);
const getRideByLegacyId = (id) =>
    api.get(`/rides/byLegacyId/${id}`).then((resp) => resp.data.ride);

export const getRideTags = () => api.get('rides/ride-tags');

const UUID_LENGTH = 36;
export const getRide = (id, getState = () => ({})) => {
    if (id.length === UUID_LENGTH) {
        return getRideById(id, getState);
    }
    return id.match(/^\d+$/)
        ? getRideByLegacyId(id)
        : getRideByShortId(id, getState);
};

export const getCustomRides = () =>
    api
        .get('rides', {
            params: { type: 'custom', includeLocationHistory: false }
        })
        .then((res) => res.data.rides);

export const deleteRide = (rideId) => api.delete(`ride/${rideId}`);

export const renderRideImage = (rideId, args = {}) => {
    const params = { strokeWidth: 12, ...args };

    const queryParams = getQueryParams(params);

    return `${baseURL}/render/svg/${rideId}?${queryParams}`;
};

export const rideMapImage = (ride, width = 320, height = 240, mapStyle = 5) => {
    const HERE_MAPS_API_KEY = process.env.HERE_MAPS_API_KEY;
    const style = `lc0=ffff6600&sc0=ffff6600&lw0=4&w=${width}&h=${height}&t=${mapStyle}`;
    const url = `${baseURL}/render/map/v2/${ride.id}?${style}&nocp`;
    if (HERE_MAPS_API_KEY) return `${url}&apiKey=${HERE_MAPS_API_KEY}`;
    return url;
};

export const gpxDownloadUrl = (rideId) => {
    const version = process.env.GPX_FORMAT
        ? `&version=${process.env.GPX_FORMAT}`
        : '';
    return `${baseURL}/gpx?rideId=${rideId}${version}`;
};

export const gpxDownloadUrlShortId = (rideId) => {
    const version = process.env.GPX_FORMAT
        ? `&version=${process.env.GPX_FORMAT}`
        : '';
    return `${baseURL}/gpx?shortId=${rideId}${version}`;
};

export const convertRecordedRide = (id, opts = {}) =>
    api.post(`/rides/record/convert/${id}`, opts);

// TODO : future params to be implemented for 2021
export const uploadRideGPX = (data, params) => {
    const config = {
        headers: {
            'Content-Type': 'application/xml'
        }
    };
    const defaultParams = {
        reverseGeocode: 'ends',
        dontSave: true,
        offRoad: false,
        includeWeather: 'imperial'
    };
    const newParams = { ...defaultParams, ...params };
    const queryParams = getQueryParams(newParams);
    return api.post(`gpx?${queryParams}`, data, config).then((res) => res);
};

//------------------------------------------------------------------------------
// Search ----------------------------------------------------------------------
export const searchRidesByCity = (query) => {
    return api.get('geo/ridecount', {
        params: { query }
    });
};

export const searchRides = (query) =>
    api.get(`rides?name=${query}&includeBookmarked=true`);

//------------------------------------------------------------------------------
// Bookmarks -------------------------------------------------------------------
export const bookmarkRide = (rideId) => api.post('rides/bookmark', { rideId });

export const deleteBookmark = (bookmarkId) =>
    api.delete(`rides/bookmark/${bookmarkId}`);

export const getBookmarks = () => api.get('rides/bookmarks');

//------------------------------------------------------------------------------
// Event Bookmarks -------------------------------------------------------------
export const bookmarkEvent = (eventId) => {
    return getIdToken()
        .then((data) => {
            const id_token = data.id_token;
            return apiForEvents.post(
                `eventserv/v2/events/bookmark/${eventId}`,
                null,
                {
                    headers: { Authorization: `Bearer ${id_token}` }
                }
            );
        })
        .catch((err) => {
            console.log('err', err);
        });
};

export const deleteEventBookmark = (bookmarkId) => {
    return getIdToken()
        .then((data) => {
            const id_token = data.id_token;
            return apiForEvents.delete(
                `eventserv/v2/events/bookmark/${bookmarkId}`,
                {
                    headers: { Authorization: `Bearer ${id_token}` }
                }
            );
        })
        .catch((err) => {
            console.log(err);
        });
};

export const getEventBookmarks = () => {
    return getIdToken()
        .then((data) => {
            const id_token = data.id_token;
            return apiForEvents.get('eventserv/v2/events/bookmarked', {
                headers: { Authorization: `Bearer ${id_token}` }
            });
        })
        .catch((err) => {
            console.log(err);
        });
};

//------------------------------------------------------------------------------
// Badges ------------------------------------------------------------------
export const getBadges = () => api.get('/achievements/badges/shared');

export const getSharedBadge = ({ badgeID, userID }) => {
    return api.get(
        `/achievements/badges/shared?badgeID=${badgeID}&userID=${userID}`
    );
};

// calls our FE server endpoint
export const getBadgeType = (type, args) => {
    return axios.get(`/badges/${type}`, args).then((resp) => resp.data);
};

export const getBadgeFrameUrls = (type, params) =>
    getBadgeType(type, { params }).then((data) => data.frameUrls);

//------------------------------------------------------------------------------
// Challenges ------------------------------------------------------------------
export const getChallenges = () => api.get('challenges');

//------------------------------------------------------------------------------
// EventTypes ------------------------------------------------------------------
// Check for details 'cause there are no updates about this on the documentation
export const getEventTypes = () => api.get('events/types');

//------------------------------------------------------------------------------
// Dealers ---------------------------------------------------------------------
const getDealerById = urlGetter('dealer', '/dealers');
const getDealerByDealerId = urlGetter(
    'dealer',
    '/dealers/byDealerId',
    'dealerId'
);

export const getDealer = (id, getState = () => ({})) =>
    (id || {}).length === UUID_LENGTH
        ? getDealerById(id, getState)
        : getDealerByDealerId(id, getState);

export const getDealers = (lat, lon, radiusInMiles) => {
    const lang = getLang().toUpperCase();
    return api.get(`dealers`, {
        params: allOrNothing({ lat, lon, radiusInMiles }),
        headers: { 'Accept-Language': `${lang},EN` }
    });
};

export const getDealerCodes = () => api.get('dealers/codes');

export const searchDealers = (text, data = {}, at = []) => {
    const lang = getLang().toUpperCase();
    const newData = allOrNothing(data);
    return api
        .get(`dealers`, {
            params: { ...newData, text },
            headers: { 'Accept-Language': `${lang},EN` }
        })
        .then((res) => {
            return highlightResults(
                res.data.dealers.map(dealerToSearchItem),
                text,
                at
            );
        });
};
//------------------------------------------------------------------------------
// Events ----------------------------------------------------------------------
const getEventById = urlGetter('event', '/events/getEvent', 'id', true);
const getEventByEventId = urlGetter(
    'event',
    '/events/getEvent',
    'eventId',
    true
);
function toISOStringLocal(d) {
    function z(n) {
        return (n < 10 ? '0' : '') + n;
    }
    return (
        d.getFullYear() +
        '-' +
        z(d.getMonth() + 1) +
        '-' +
        z(d.getDate()) +
        'T' +
        '00' +
        ':' +
        '00' +
        ':' +
        '00'
    );
}

export const getEvent = (id, getState = () => ({})) =>
    (id || {}).length === UUID_LENGTH
        ? getEventById(id, getState)
        : getEventByEventId(id, getState);

export const getEventsWithParams = (params) => {
    const lang = getLang().toUpperCase();
    const currentDate = new Date();
    const currentDateToIsoLocal = toISOStringLocal(currentDate);
    params.endsAfter = `${currentDateToIsoLocal}Z`;
    return apiForEvents
        .get(`eventserv/v2/events/searchEvents`, {
            params: params,
            headers: { 'Accept-Language': `${lang},EN` }
        })
        .then((result) => {
            const now = new Date().getTime();
            const startOfToday = now - (now % 86400000);
            return {
                ...result,
                data: {
                    ...result.data,
                    events: result.data.events
                        // only show events whose endDate is in the future
                        .filter(
                            (event) =>
                                new Date(event.endDate).getTime() >=
                                startOfToday
                        )
                        // attach latitude and longitude
                        .map((event) => {
                            const { latitude, longitude } =
                                event.eventActivities[0];
                            return { ...event, latitude, longitude };
                        })
                        .sort((a, b) => dayjs(a.startDate) - dayjs(b.startDate))
                }
            };
        });
};

export const getEvents = (lat, lon, radius) => {
    const lang = getLang().toUpperCase();
    const currentDate = new Date();
    const currentDateToIsoLocal = toISOStringLocal(currentDate);
    return apiForEvents
        .get(`eventserv/v2/events/searchEvents`, {
            params: allOrNothing({
                radius: `${lat},${lon},${radius}`,
                pageSize: 1000,
                endsAfter: `${currentDateToIsoLocal}Z`
            }),
            headers: { 'Accept-Language': `${lang},EN` }
        })
        .then((result) => {
            const now = new Date().getTime();
            const startOfToday = now - (now % 86400000);

            return {
                ...result,
                data: {
                    ...result.data,
                    events: result.data.events
                        // only show events whose endDate is in the future
                        .filter(
                            (event) =>
                                new Date(event.endDate).getTime() >=
                                startOfToday
                        )
                        // attach latitude and longitude
                        .map((event) => {
                            const { latitude, longitude } =
                                event.eventActivities[0].address;
                            return { ...event, latitude, longitude };
                        })
                        .sort((a, b) => dayjs(a.startDate) - dayjs(b.startDate))
                }
            };
        });
};

//------------------------------------------------------------------------------
// App Content -----------------------------------------------------------------
export const getAppContent = (id) => {
    const lang = getLang().toUpperCase();
    return api
        .get(`app-content/forLink?deepLink=${id}`, {
            headers: { 'Accept-Language': `${lang},EN` }
        })
        .then((result) => {
            return {
                ...result,
                data: {
                    ...result.data
                }
            };
        });
};
//------------------------------------------------------------------------------
// Weather ---------------------------------------------------------------------
export const getForecast = (lat, lon, time) => {
    const optTime = time ? `,${time}` : '';
    return axios
        .get(`/weather/${lat},${lon}${optTime}`, {
            params: {
                exclude: 'minutely,hourly,daily',
                units: 'us'
            }
        })
        .then((resp) => resp.data.currently)
        .catch((error) => error);
};
//------------------------------------------------------------------------------
// Here EV ---------------------------------------------------------------------
export const getHereEv = (
    { lat, lng, radius = 500, connectortype },
    maxresults = 200
) => {
    return axios
        .get('/hereev/', {
            params: {
                lat,
                lng,
                radius,
                connectortype,
                maxresults
            }
        })
        .then((resp) => resp.data)
        .catch((error) => error);
};
//------------------------------------------------------------------------------
// Electrify AM EV -------------------------------------------------------------
export const getElectrifyAmEv = ({ lat, lng, radius = 500 }) => {
    return axios
        .get('/api/ocpi/2.1.1/locations', {
            params: {
                lat,
                lon: lng,
                radius
            }
        })
        .then((resp) => resp.data)
        .catch((error) => error);
};
//------------------------------------------------------------------------------
// Location --------------------------------------------------------------------
export const getLocation = () => {
    return axios
        .get('/getmylocation')
        .then((response) => response)
        .catch((error) => error);
};

export default api;
