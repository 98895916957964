/******************************************************************************\
 * File: Ride.jsx
 *
 * Author: Gigster
 *
 * Description: Ride UI
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import dayjs from 'dayjs';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/cards/Ride.scss';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Card from '@/components/common/cards/Card';
import Bookmark from '@/components/common/icons/Bookmark';
import Link from '@/components/common/header/Link';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { rideImage, prettyTime, prettyMiles } from '@/helpers/functions';
import { rideMapImage } from '@/helpers/api';
import { setBookmark, isBookmarked } from '@/store/bookmarks';
import {
    hoverRide,
    unhoverRide,
    selectRide,
    previewRide,
    setCurrentRouteProgress
} from '@/store/rides';
import { editRide } from '@/store/edit_ride';
import { Routes, ridePath, dealerUrl } from '@/helpers/routes';
import { currentUserCanEditRide, currentUserId } from '@/store/auth';
import { RideSubtype } from '@/helpers/constants';
import { getRideCheckboxData } from '@/helpers/checkboxes';
import RideEvent from '@/components/common/RideEvent';
import { analyticsForRideWithData } from '@/helpers/analytics';
import withRouter from '@/helpers/hooks';
import { translate } from '@/helpers/i18n';
const t = translate('cards.Ride');
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const RideDetail = ({ ride, isSidePanel, isHome }) => {
    const formattedDate = (dateTime) =>
        `${dayjs(dateTime).format('MMM D, YYYY')}`;
    const timeFormatted = prettyTime(ride.duration);
    const { isPlannedRide, isRecordedRide, isCustomRide, offRoad } = ride;

    return (
        <div className={style.RideDetail}>
            {!isSidePanel && (
                <h6 className={style.detail}>
                    {!offRoad && (
                        <span>
                            {timeFormatted}
                            <span className={style.bullet}>•</span>
                        </span>
                    )}
                    {prettyMiles(ride.length)}
                </h6>
            )}
            {!!ride.dealerId && (
                <Link
                    className={style.detail}
                    to={`${dealerUrl(ride.dealerId)}?sidebar=1`}>
                    {ride.dealerName
                        ? t('recommendedBy', {
                              dealer: ride.dealerName,
                              t: 'Recommended By: ${ride.recommendedBy || ride.dealerName}'
                          })
                        : ` ${t('Recommended Ride')}`}
                </Link>
            )}
            <div className={style.detail}>
                {`${t('Last Modified:')} `}
                {formattedDate(ride.modifiedTime)}
            </div>
            {isRecordedRide && (
                <div className={style.detail}>
                    {t('Ride Finished:')}{' '}
                    {formattedDate(ride.session.finishedTime)}
                </div>
            )}
            {!!ride.event && !isHome && isSidePanel && (
                <RideEvent event={ride.event} isSidePanel={true} />
            )}
        </div>
    );
};

const RideAction = ({
    ride,
    isBookmarked,
    onClickBookmark,
    isSidePanel,
    idLabel,
    rowIndex
}) => {
    const cn = classNames(style.Bookmark, {
        [style.Wide]: !!ride.wide,
        [style.isSidePanel]: isSidePanel
    });
    const id = idLabel ? `${idLabel + '-favorite-' + rowIndex}` : undefined;
    const dataId = idLabel ? `${idLabel + '-favorite-' + rowIndex}` : undefined;
    return (
        <Link
            className={cn}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClickBookmark(ride);
            }}
            id={id}
            data-testid={dataId}>
            <Bookmark active={isBookmarked} />
        </Link>
    );
};

class Ride extends React.Component {
    rideTypeDetail = (ridetypes = [], ride, isSidePanel, rideTags = []) => {
        if (isSidePanel) ridetypes = getRideCheckboxData(rideTags);
        const { currentuserid } = this.props;
        const { transportMode, offRoad, type } = ride;
        const isBicycleRoute = transportMode === 'BICYCLE';
        // Note: Avoid side effects
        const rideSubType =
            ride?.userId !== currentuserid && type === 'CUSTOM'
                ? 'SHARED'
                : ride.subType;
        const rideType = ridetypes.find((val) => val.value === rideSubType);
        if (!rideType) return;
        return (
            <span className={style.RideType} style={{ paddingTop: '2px' }}>
                <img
                    src={`/img/${rideType.typeIcon}`}
                    // className={style.iconHelmet}
                />
                <span style={{ paddingRight: '4px' }}>
                    {rideType.type} • {rideType.label}
                    {isBicycleRoute && (
                        <span>
                            {' '}
                            •{' '}
                            <img
                                src={'/img/icon-ebike.svg'}
                                style={{ width: '32px' }}
                            />
                        </span>
                    )}
                    {!isBicycleRoute && !!offRoad && (
                        <span>
                            {' '}
                            •{' '}
                            <img
                                src={'/img/icon-route-type-off-road.svg'}
                                className={style.offRoadIcon}
                            />
                        </span>
                    )}
                </span>
            </span>
        );
    };

    toggleBookmark = () => {
        const { isBookmarked, deleteBookmark, addBookmark, item } = this.props;
        if (!!deleteBookmark && !!isBookmarked) deleteBookmark(item.id);
        if (!isBookmarked)
            analyticsForRideWithData(`star ride`, {
                id: item.id,
                location: 'sidebar list view',
                dealerId: item.dealerId
            });
        this.props.setBookmark(!isBookmarked);
    };

    onSelect = (e) => {
        const { selectRide, item, navigate } = this.props;
        selectRide(navigate);
        this.onSelectItem(e);
        ridePath(Routes.RIDE_PREVIEW, item);
        e.preventDefault();
    };

    onSelectItem = () => {
        const { item, didSelectItem } = this.props;
        didSelectItem && didSelectItem(item);
    };
    onSelectItemImages = (e) => {
        console.log('onSelectItemImages');
        e.preventDefault();
    };

    render() {
        const {
            className,
            item: ride,
            setBookmark,
            onSelect,
            isBookmarked,
            fadeUnbookmarked,
            partiallyExpanded,
            hoverPreviewRide,
            hoverUnpreviewRide,
            didSelectItem,
            editable,
            onDelete,
            forkable,
            onEditRide,
            canEdit,
            selectRide,
            rideCheckboxData,
            deleteBookmark,
            addBookmark,
            isMobile,
            large,
            isSidePanel,
            isHome,
            rideTags,
            currentuserid,
            idLabel,
            rowIndex,
            onResetSetCurrentRouteProgress,
            ...rest
        } = this.props;

        ride.wide = this.props.wide || false;
        ride.isMobile = isMobile || false;
        const { isRecordedRide, hasPhotos, offRoad } = ride;
        const cn = classNames({
            [className]: className,
            [style.Fade]: true,
            [style.Tall]: true,
            [style['fade-out']]:
                (fadeUnbookmarked && !isBookmarked) || ride.loading,
            [style['isSidePanel']]: isSidePanel
        });

        const image = rideMapImage(ride, 400 * 2, 200 * 2);

        // ride links
        const previewLink = {
            text: t('Preview'),
            onClick: () => {
                onResetSetCurrentRouteProgress();
                this.onSelectItem();
            },
            to: ridePath(Routes.RIDE_PREVIEW, ride)
        };

        const editLink =
            ride && isRecordedRide
                ? {
                      text: t('Copy & Plan'),
                      onClick: onEditRide,
                      to: ridePath(Routes.RIDE_PREVIEW, ride)
                  }
                : {
                      text: t('Edit'),
                      onClick: this.onSelectItem,
                      to: ridePath(Routes.RIDE_EDIT, ride)
                  };

        const deleteLink = onDelete
            ? { text: t('Delete'), onClick: () => onDelete(ride) }
            : null;

        const forkLink =
            forkable && canEdit
                ? { ...editLink, text: t('Edit ride') }
                : {
                      text: isRecordedRide ? t('Copy & Plan') : t('Customize'),
                      onClick: onEditRide
                  };

        const footerLinks = editable
            ? [previewLink, editLink, deleteLink]
            : [previewLink, forkLink];

        return (
            <Card
                className={cn}
                to={ridePath(Routes.RIDE_PREVIEW, ride)}
                typeDetail={this.rideTypeDetail(
                    rideCheckboxData,
                    ride,
                    isSidePanel,
                    rideTags
                )}
                title={ride.name}
                offRoad={offRoad}
                image={image}
                hasImages={hasPhotos && '/img/icon-ride-photo.svg'}
                Action={RideAction}
                actionProps={{
                    ride,
                    isBookmarked,
                    onClickBookmark: this.toggleBookmark,
                    idLabel,
                    rowIndex
                }}
                Detail={RideDetail}
                detailProps={{ ride, isSidePanel, isHome, idLabel, rowIndex }}
                onClick={this.onSelectItem}
                onClickImages={this.onSelectItemImages}
                footerLinks={footerLinks}
                large={large}
                isSidePanel={isSidePanel}
                isride={`${true}`}
                onMouseEnter={() => partiallyExpanded && hoverPreviewRide()}
                onMouseLeave={() => partiallyExpanded && hoverUnpreviewRide()}
                currentuserid={currentuserid}
                idLabel={idLabel}
                rowIndex={rowIndex}
                {...rest}
            />
        );
    }
}
//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state, ownProps) => {
    return {
        isBookmarked: isBookmarked(ownProps.item.id, state),
        canEdit: currentUserCanEditRide(state, ownProps.item),
        currentuserid: currentUserId(state),
        rideTags: state.map.rideTags
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = (dispatch, ownProps) => {
    const { item } = ownProps;
    const { id, shortId } = item;
    return {
        setBookmark: (value) => dispatch(setBookmark(id, value)),
        hoverPreviewRide: () => dispatch(hoverRide(shortId || id)),
        hoverUnpreviewRide: () => dispatch(unhoverRide(id)),
        onEditRide: () => dispatch(editRide(item)),
        selectRide: (navigate) => dispatch(selectRide(id, navigate)),
        onResetSetCurrentRouteProgress: () =>
            dispatch(setCurrentRouteProgress(0))
    };
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(Ride);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(container);
