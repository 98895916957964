/******************************************************************************\
 * File: Notifications.jsx
 *
 * Author: Gigster
 *
 * Description:
 *
 * Notes:
 \******************************************************************************/

//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
//------------------------------------------------------------------------------
// My Modules ------------------------------------------------------------------
import Close from '@/components/common/icons/Close';
import { clearError } from '@/store/error';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import * as style from '@/style/common/Notifications.scss';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
const Notifications = ({ toast, clearError }) => {
    useEffect(() => {
        if (toast) {
            const timer = setTimeout(() => {
                clearError(toast);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [toast, clearError]);

    if (!toast) {
        return null;
    }

    const { message, dismissable } = toast;

    return (
        <div className={style.Notifications}>
            <div className={style.Notification}>
                <div className={style.message}>{message}</div>
                {dismissable !== false && (
                    <Close
                        className={style.Close}
                        onClick={() => clearError(toast)}
                    />
                )}
            </div>
        </div>
    );
};

//------------------------------------------------------------------------------
// Redux State -----------------------------------------------------------------
const mapStateToProps = (state) => {
    return {
        toast: state.error.errors[0]
    };
};
//------------------------------------------------------------------------------
// Redux Actions ---------------------------------------------------------------
const mapDispatchToProps = {
    clearError // shorthand notation that automatically wraps in dispatch
};
//------------------------------------------------------------------------------
// Redux Connect ---------------------------------------------------------------
const container = connect(mapStateToProps, mapDispatchToProps)(Notifications);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default container;
